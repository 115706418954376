import { Box, Checkbox, FormControlLabel } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import * as yup from "yup";

const LeadStatuses = () => {
  const initialValues = {
    name: "",
    color: "",
    is_final: false,
    is_archived: false,
  };
  return (
    <SetupCC
      shouldReload
      name="Status"
      baseRouteKey="leadStatuses"
      moduleKey="lead-status"
      columns={[
        { id: "name", label: "Status Name", minWidth: 100 },
        { id: "color", label: "Status Color", align: "center" },
        { id: "is_archived", label: "Is Archived", align: "center" },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "color" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: value,
                    height: "20px",
                    width: "20px",
                    borderRadius: "5px",
                  }}
                ></Box>
              </Box>
            ) : column?.id === "name" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {value}
                {!!row.is_final && (
                  <Box sx={{ color: "green", fontSize: "15px" }}>
                    <HiOutlineCheckBadge />
                  </Box>
                )}
              </Box>
            ) : column?.id === "is_archived" ? (
              <Box sx={{ textAlign: "center" }}>
                {!!value ? (
                  <Box
                    sx={{ color: "gray", fontSize: "15px", marginLeft: "5px" }}
                  >
                    Archived
                  </Box>
                ) : (
                  "-"
                )}
              </Box>
            ) : (
              value
            )}
          </>
        );
      }}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Status name required!"),
        color: yup.string().required("Status color required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Status Name" formik={formik} />
          <InputField
            label="Status Color"
            name="color"
            type="color"
            formik={formik}
            InputLabelProps={{ shrink: true }}
          />
          <div style={{ display: "flex", gap: "10px" }}>
            <FormControlLabel
              control={<Checkbox />}
              label="Is final?"
              checked={formik.values.is_final}
              onChange={(e) =>
                formik.setFieldValue("is_final", e.target.checked)
              }
            />
            <FormControlLabel
              control={<Checkbox />}
              label="Is archived?"
              checked={formik.values.is_archived}
              onChange={(e) =>
                formik.setFieldValue("is_archived", e.target.checked)
              }
            />
          </div>
        </>
      )}
    ></SetupCC>
  );
};

export default LeadStatuses;
